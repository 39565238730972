/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css';
import './src/styles/styles.scss';

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  try {
    const { disableScrollUpdate } = location.state;
    if (disableScrollUpdate) {
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export { shouldUpdateScroll };
